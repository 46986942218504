import { ApiErrorCode, ApiResult } from "alfa-wza-types";

export default class ApiService {
  static url = "https://api.arr.ivotingbusiness.dev2.itdotfocus.com/";

  static call = async (method: string, url: string) => {
    const headers = {
      "Content-Type": "application/json"
    };

    const response = await fetch(`${ApiService.url}${url}`, {
      method: method,
      headers: headers,
      credentials: "include"
    });

    console.log("response", response);
    const data = await response.json();
    console.log("data", data);

    switch (data.result) {
      case ApiResult.ok:
        return data.payload;
      case ApiResult.error:
      default:
        throw data;
    }
  };

  static get = async (url: string) => {
    return await ApiService.call("GET", url);
  };
}
