import React from "react";
import {WzaQuestionEntity} from "alfa-wza-types";
import QuestionTitle from "../Layout/QuestionTitle";
import {CSSTransition} from "react-transition-group";

interface Props {
    question: WzaQuestionEntity;
}

export default function VotingQuestion(props: Props) {
    return <CSSTransition timeout={0} classNames="chart" in={true} appear={true} unmountOnExit>
        <div className="chart">
            <QuestionTitle question={props.question}/>
            <div>
                <img src="/assets/vote-now.png" alt="" className="vote-now-img"/>
            </div>
            <h4>Trwa głosowanie nad uchwałą... Użyj swojego urządzenia.</h4>
        </div>
    </CSSTransition>;
}