import React from "react";
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';

import { WzaQuestionEntity } from "alfa-wza-types";
import QuestionTitle from "../Layout/QuestionTitle";
import { CSSTransition } from "react-transition-group";
import Legenda from '../Legenda';

const COLORS = ['#00a45a', '#a51727', '#bb9c71', '#424242'];
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = (data: number[]) => ({
       cx, cy, midAngle, innerRadius, outerRadius, percent, index,
   }: any) => {
    if(percent === 0) return null;
    const radius = innerRadius + (outerRadius - innerRadius) * 1.2;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" fontSize={30}>
            {`${data[index]}: ${(percent * 100).toFixed(4)}%`}
        </text>
    );
};

interface Props {
    question: WzaQuestionEntity;
    votesAbandoned: number;
}

export default function Chart(props: Props) {
    const { votesAbandoned } = props;
    const { votesYes, votesNo, votesAbstain } = props.question;

    const data = [
        {
            "name": "ZA",
            "value": votesYes,
        },
        {
            "name": "PRZECIW",
            "value": votesNo,
        },
        {
            "name": "WSTRZYMANE",
            "value": votesAbstain,
        },
        {
            "name": "NIEODDANE",
            "value": votesAbandoned,
        },
    ];

    return <CSSTransition timeout={0} classNames="chart" in={true} appear={true} unmountOnExit>
        <div className="chart">
            <QuestionTitle question={props.question} />
            <Legenda />
            <ResponsiveContainer width="100%" height="70%">
                <PieChart>
                    <Pie
                        data={data}
                        dataKey="value"
                        nameKey="name"
                        fill="#8884d8"
                        labelLine={false}
                        label={renderCustomizedLabel([votesYes, votesNo, votesAbstain, votesAbandoned])}
                    >
                        {
                            data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                        }
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        </div>
    </CSSTransition>;
}
