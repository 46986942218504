import * as React from 'react';
import './Alert.scss';

interface AlertProps {
    type: string,
    text: string,
    simple?: boolean,
}

const Alert = (props: AlertProps) => (
    <div className={`alert alert-${props.type} ${props.simple ? 'alert-simple' : ''}`}>{props.text}</div>
);

export default Alert;