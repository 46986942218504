import React from "react";
import Alert from "../../Common/Alert";
import {WzaQuestionEntity} from "alfa-wza-types";

import './QuestionTitle.scss';

interface Props {
    question: WzaQuestionEntity;
}

export default function QuestionTitle(props: Props) {
    const {title, isPrivateVoting} = props.question;

    return <div className="main-question">
            <h3>{title}</h3>
            {isPrivateVoting && <Alert simple type='notice' text='Głosowanie tajne' />}
        </div>;
}