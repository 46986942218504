import React from 'react';
import './App.scss';
import InteractiveChart from "./components/InteractiveVotingInfo";

const App: React.FC = () => {
  return (
      <InteractiveChart/>
  );
};

export default App;
