import * as React from "react";
import "./Footer.scss";

const Index = () => {
  return (
    <footer>
      <div className="footer-wrapper">
        <p>&copy; iVoting Business. Wykonanie </p>
        <a target="_blank" href="https://itdotfocus.com/pl/o-nas">
          <img
            className="menu-footer_logo"
            src="/assets/logo_IT_focus.png"
            alt="IT.focus"
          />
        </a>
      </div>
    </footer>
  );
};

export default Index;
