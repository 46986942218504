import React from 'react';
import Footer from '../Footer';

import './Main.scss';

interface Props {
    children: React.ReactNode;
}

const Main = (props: Props) => {
    return <main>{props.children}<Footer /></main>
}

export default Main;