import React from "react";
import {Main, PageHeader} from "../Layout";
import Chart from "../Chart";

import "./InteractiveVotingInfo.scss";
import {WzaQuestionEntity} from "alfa-wza-types";
import ApiService from "../../services/api-service";
import VotingQuestion from "../VotingQuestion";
import WelcomeScreen from "../WelcomeScreen";
import Logo from "../Common/Logo";

interface State {
    previousQuestion: WzaQuestionEntity | null;
    currentQuestion: WzaQuestionEntity | null;
    previousVotesAbandoned: number;
    votesAbandoned: number;
}

export default class InteractiveChart extends React.Component<{}, State> {
    private intervalId?: NodeJS.Timeout;

    state = {
        previousQuestion: null,
        currentQuestion: null,
        previousVotesAbandoned: 0,
        votesAbandoned: 0,
    };

    componentDidMount() {
        this.intervalId = setInterval(this.updateQuestionInfo, 5000);
        this.updateQuestionInfo();
    }

    componentWillUnmount() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    getNotYetVotedSharesCount = async (): Promise<number> => {
        try {
            const stats = await ApiService.get("question/public-stats-who-did-not-vote");
            return stats && stats.notYetVotedTotalShares ? stats.notYetVotedTotalShares : 0;
        } catch (e) {
            return 0;
        }
    }

    updateQuestionInfo = async () => {
        const question = await ApiService.get("question/current/public/hndneoilCENOweoinwoui3894yDNOwsku");
        const votesAbandoned = await this.getNotYetVotedSharesCount();

        if (question) {
            console.log('Setting state',
                {
                    previousQuestion: question,
                    currentQuestion: null,
                    previousVotesAbandoned: votesAbandoned,
                });
            this.setState({
                previousQuestion: question,
                currentQuestion: null,
                previousVotesAbandoned: votesAbandoned,
            });
        } else if (this.state.currentQuestion === null) {
            console.log('Setting state',
                {
                    previousQuestion: null,
                    previousVotesAbandoned: 0,
                    currentQuestion: this.state.previousQuestion
                        ? {
                            ...((this.state.previousQuestion as unknown) as WzaQuestionEntity)
                        }
                        : null,
                    votesAbandoned: this.state.previousQuestion
                        ? this.state.previousVotesAbandoned : 0,
                });
            this.setState({
                previousQuestion: null,
                previousVotesAbandoned: 0,
                currentQuestion: this.state.previousQuestion
                    ? {
                        ...((this.state.previousQuestion as unknown) as WzaQuestionEntity)
                    }
                    : null,
                votesAbandoned: this.state.previousQuestion
                    ? this.state.previousVotesAbandoned : 0,
            });
        }
    };

    render() {
        const {currentQuestion, previousQuestion, votesAbandoned} = this.state;
        return (
            <div className="interactive-chart">
                <Main>
                    <Logo/>
                    <PageHeader title="iVoting Business 2020"/>
                    {!currentQuestion && !previousQuestion ? (
                        <WelcomeScreen/>
                    ) : currentQuestion ? (
                        <Chart
                            question={(currentQuestion as unknown) as WzaQuestionEntity}
                            votesAbandoned={(votesAbandoned as unknown) as number}
                        />
                    ) : (
                        previousQuestion && (
                            <VotingQuestion
                                question={(previousQuestion as unknown) as WzaQuestionEntity}
                            />
                        )
                    )}
                </Main>
            </div>
        );
    }
}
