import React from "react";
import "./Logo.scss";

const Logo = () => (
  <div className="logo">
    <div className="img-wrapper">
      <img src="/assets/logo.png" alt="iVoting Business" />
    </div>
  </div>
);

export default Logo;
