import React from "react";
import { WzaQuestionEntity } from "alfa-wza-types";
import QuestionTitle from "../Layout/QuestionTitle";
import { CSSTransition } from "react-transition-group";
import Logo from "../Common/Logo";

export default function WelcomeScreen() {
  return (
    <CSSTransition
      timeout={0}
      classNames="chart"
      in={true}
      appear={true}
      unmountOnExit
    >
      <div className="chart">
        <Logo />
      </div>
    </CSSTransition>
  );
}
