import React from "react"

import './Legenda.scss';

const Legenda = () => {
  return(
    <div className="legenda">
      <h2>Legenda</h2>
      <div className="legenda-option">
        <div className="vote-yes"></div>
        <span className="text">Głosy za</span>
      </div>
      <div className="legenda-option">
        <div className="vote-no"></div>
        <span className="text">Głosy przeciw</span>
      </div>
        <div className="legenda-option">
            <div className="vote-abstain"></div>
            <span className="text">Wstrzymano od głosu</span>
        </div>
        <div className="legenda-option">
            <div className="vote-abandon"></div>
            <span className="text">Nie oddano głosu</span>
        </div>
    </div>
  )
}

export default Legenda;